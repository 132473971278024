import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Shipping } from "./components/Shipping";
import { Software } from "./components/Software";

function App() {
  return (
    <div className="">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/shipping" element={<Shipping />}></Route>
          <Route path="/software" element={<Software />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
