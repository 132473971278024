import React, { useEffect, useRef, useState } from "react";
import Shipping1 from "../images/Shipping1.png";
import Shipping2 from "../images/Shipping2.png";
import team from "../images/team.svg";
import solution from "../images/solution.svg";
import div1 from "../images/Established Professional Network.svg";
import div2 from "../images/Focused Outreach.svg";
import div3 from "../images/Industry-Focused Expertise.svg";
import div4 from "../images/Resourceful Hiring Strategy.svg";
import div5 from "../images/Global.svg";
import div6 from "../images/Professionalism.svg";
import div7 from "../images/Accountability.svg";
import div8 from "../images/Cost Efficiency.svg";
import div9 from "../images/Security Focus.svg";
import div10 from "../images/Trusted Subcontractors.svg";
import set1 from "../images/set1.svg";
import set2 from "../images/set2.svg";
import set3 from "../images/set3.svg";
import set4 from "../images/set4.svg";
import set5 from "../images/set5.svg";
import set6 from "../images/set6.svg";
import div11 from "../images/Cross-border Dynamics.svg";
import people1 from "../images/Ellipse 1.png";
import people2 from "../images/Ellipse 2.png";
import people3 from "../images/Ellipse 3.png";
import linkedin from "../images/linkedin.svg";
import mail from "../images/mail.svg";
import { useNavigate } from "react-router-dom";

export const Shipping = () => {
  const navigate = useNavigate();
  const data = [
    "Established Professional Network",
    "Focused Outreach",
    "Industry-Focused Expertise",
    "Resourceful Hiring Strategy",
    "Global Scalability Sustained in Rigorous Testing",
    "Professionalism",
    "Accountability",
    "Cost Efficiency",
    "Security Focus",
    "Trusted Subcontractors",
    "Cross-border Dynamics",
  ];

  const divisions = [
    {
      logo: div1,
      title: "Established Professional Network",
      content:
        "We have an organically developed network of contacts directly or indirectly connected to the shipping industry.",
    },
    {
      logo: div2,
      title: "Focused Outreach",
      content:
        "We employ a targeted advertising approach across selected platforms and institutions in specific high-talent jurisdictions to attract top candidates.",
    },
    {
      logo: div3,
      title: "Industry-Focused Expertise",
      content:
        "We tailor our services to meet the unique needs of shipping companies, delivering customized solutions that align with the industry’s specific challenges and goals.",
    },
    {
      logo: div4,
      title: "Resourceful Hiring Strategy",
      content:
        "We excel at processing large amounts of information to identify undervalued candidates poised for success in your organization, using a meticulous data-driven evaluation of employees' educational and work history, recruitment tests and AI-driven CV assessment with input from recruiters in select jurisdictions.",
    },
    {
      logo: div5,
      title: "Global Scalability Sustained in Rigorous Testing",
      content:
        "Our hybrid recruitment-software solutions are meticulously crafted and systematically tested for logic and reasoning that can be seamlessly applied on a worldwide scale, ensuring consistency and reliability across borders.",
    },
    {
      logo: div6,
      title: "Professionalism",
      content:
        "We understand that attracting talent online can require a “leap of faith”. As such, ENTNT maintains a consistent professional approach ensuring transparency, credibility and reliability in its presence and interactions.",
    },
    {
      logo: div7,
      title: "Accountability",
      content:
        "We offer services beyond being a mere intermediary between an employer and employee, including training and management by experienced project managers to ensure seamless integration or project handling.",
    },
    {
      logo: div8,
      title: "Cost Efficiency",
      content:
        "With reduced labor costs, we attract, retain, and incentivize high-performing employees, maintaining a substantial pool of skilled professionals for scalability and flexibility.",
    },
    {
      logo: div9,
      title: "Security Focus",
      content:
        "We prioritize security from both a legal and technical perspective, ensuring compliance with international law, robust authentication, authorization and role-based access control measures, audit trails, automated monitoring and employee security training.",
    },
    {
      logo: div10,
      title: "Trusted  Subcontractors",
      content:
        "We leverage our relationships with trusted subcontractors for specialized tasks, ensuring comprehensive service.",
    },
    {
      logo: div11,
      title: "Cross-border Dynamics",
      content:
        "Our management team's skills and hands-on approach complement the global intersection of teams, products, and projects, with a vast network of reliable referrals, regulatory adherence and experience in software customization and AI integration.",
    },
  ];
  const people = [
    { image: people1, name: "Omiros", title: "CEO" },
    { image: people2, name: "Anthony", title: "COO" },
    { image: people3, name: "Marios", title: "Partner-Software" },
  ];

  const sets = [
    {
      image: set1,
      title: "Accomplished Team",
      content:
        " Experienced programmers and project managers based in Greece oversee our offshore teams.",
    },
    {
      image: set2,
      title: "Multicultural Expertise",
      content:
        " Our management team has expertise in managing remote multicultural software and IT teams in the shipping industry.",
    },
    {
      image: set3,
      title: "Shipping Knowledge",
      content:
        " Our offshore staff have shipping experience and knowledge of distributed business practices.",
    },
    {
      image: set4,
      title: "ERP and Tools",
      content:
        " We have extensive experience and knowledge of shipping ERP, HSQE, crew management, chartering and operations tools.",
    },
    {
      image: set5,
      title: "Diverse Capabilities",
      content:
        "Our capabilities range from building entire applications to working on legacy systems, integrations, mobile applications and reports within the shipping software domain.",
    },
    {
      image: set6,
      title: "Competitive Pricing",
      content:
        " We offer competitive pricing enabled by a distributed workforce, lack of cost-of-living expenses and access to high-talent locations. ",
    },
  ];
  const [selectedItem, setSelectedItem] = useState<null | string>(null);

  const [activeSection, setActiveSection] = useState<number | null>(null);
  useEffect(() => {
    const handleIntersection = (entries: any[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(parseInt(entry.target.id.replace("item-", ""), 10));
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    });

    divisions.forEach((_, i) => {
      const sectionElement = document.getElementById(`item-${i}`);
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });
    return () => {
      observer.disconnect();
    };
  }, [divisions]);

  return (
    <div className="px-2.5 md:px-36 md:mt-24 mt-20 space-y-3 md:pb-20 pb-8">
      {/* Section 1 */}
      <div className="flex md:flex-row flex-col-reverse rounded-tl-3xl bg-[#DAD7FF]">
        <div className="flex flex-col md:flex-col md:justify-left md:pl-24  md:py-20 md:w-1/2 px-7 py-7">
          <p className="text-black md:text-4xl text-2xl font-bold">
            Looking to reduce operational costs while increasing productivity?
          </p>
          <p className="text-[#3B31DE] md:text-4xl text-2xl font-bold md:mt-5 mt-1">
            ENTNT has the solution.
          </p>

          <p className="text-black text-left font-medium md:mt-10 mt-5 ">
            We specialize in providing and managing skilled remote professionals
            alongside specific hybrid software-AI solutions, enabling you to
            optimize efficiency without compromising on quality.
          </p>
          <div className="flex space-x-5 md:mt-12 mt-8 md:mb-0 mb-12 ">
            {/*<button
              type="button"
              className="inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-4 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={() => navigate("/")}
            >
              Get Started
            </button>
            <button
              type="button"
              className=" inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-2 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            >
              Book a Meeting
            </button>*/}
          </div>
        </div>
        <div className="md:mr-16 md:ml-10 md:-mt-12 -mt-10 md:w-fit md:mb-20 px-7">
          <img
            src={Shipping1}
            alt="image"
            className="rounded-2xl h-48 w-full md:rounded md:h-fit"
          />
        </div>
      </div>

      {/* section 2 */}
      <div className="flex md:flex-row flex-col bg-[#05005D] ">
        <div className=" md:ml-16 md:mr-10 md:-mt-12 -mt-10 md:fit md:mb-20 px-7">
          <img
            src={Shipping2}
            alt="image"
            className="rounded-2xl h-48 w-full md:rounded md:h-fit"
          />
        </div>
        <div className="flex flex-col md:justify-end md:pr-20 md:pl-8 md:pb-16 md:pt-10 md:w-1/2 mt-5 p-7">
          <div>
            {" "}
            <p className="text-[#847CFF] md:text-4xl text-2xl font-normal uppercase">
              THE ERA OF
            </p>
            <p className="text-white md:text-4xl text-2xl font-bold mt-2">
              GLOBAL TALENT MOBILITY
            </p>
          </div>

          <p className="text-white text-left font-normal md:mt-6 mt-4">
            In a time where employers have unprecedented access to a global
            talent pool, who in turn have unprecedented access to individual
            educational tools for self-awareness and self-advancement, the scene
            is set for the “invisible hand” of free market economics to be
            applied on a global scale. <br />
            <br />
            This shift is inevitable and as businesses swiftly adjust to each
            wave of expansion, they stand to gain even more from streamlining
            their labor and technological processes. Much like the worldwide
            manufacturing and internet revolutions that preceded it, this
            transformation is the new norm.
          </p>
        </div>
      </div>
      {/* section 3 */}
      <div className="flex flex-col space-y-8 bg-[#03002D] md:py-20 md:px-24 px-7 py-8">
        <div className="flex md:space-x-4 space-x-2 ">
          <p className="text-[#847CFF] md:text-4xl text-2xl font-bold">WHY</p>
          <p className="text-white md:text-4xl text-2xl font-bold">SHIPPING?</p>
        </div>
        <p className="text-white text-left font-normal md:w-4/5">
          Shipping is the embodiment of globalization before the term was
          invented: conducted in the English-language, rich with a multicultural
          diversity of people, ships, markets and deeply international, shipping
          is the lifeblood of global trade.
        </p>
        <div className="flex md:flex-row flex-col md:space-x-3 md:space-y-0 space-y-5">
          <div className="flex flex-col bg-[#05005D]  bg-opacity-25 border-2 border-[#847CFF] md:px-16 md:py-10 p-7 rounded-lg md:w-3/5">
            <img src={team} alt="image" className="h-12 w-12" />
            <p className="text-white text-left font-normal mt-4">
              Leveraging years of industry experience from our management team
              and a deep understanding of its challenges, we bridge the gaps in
              shipping-specific software and reliable staffing solutions. Our
              flexible approach allows us to create customized solutions,
              whether by building new systems or by enhancing existing software,
              each overlayed with AI development.
              <br />
              <br /> With dedicated support teams to handle software management,
              your existing staff can focus on critical tasks, ensuring enhanced
              efficiency and optimized resources, contributing to overall
              employee retention and satisfaction.{" "}
            </p>
          </div>
          <div className="flex flex-col bg-[#05005D]  bg-opacity-25  border-2 border-[#847CFF] md:px-16 md:py-6 p-7 rounded-lg md:w-2/5">
            <img src={solution} alt="image" className="h-20 w-20" />
            <p className="text-white text-left font-normal ">
              We understand the primary needs of the shipping industry to
              provide practical solutions to actual challenges in order to
              enhance existing software functionality: integration between
              software, bespoke software module creations or customizations,
              automation processes and reporting analytics.
            </p>
          </div>
        </div>
      </div>

      {/* section 4 */}
      <div className="flex md:flex-row flex-col bg-[#847CFF] md:max-h-[700px]">
        <div className="flex flex-col justify-left md:pl-28 md:pr-16 md:py-20 px-7 py-8 md:w-1/2">
          <div>
            {" "}
            <p className="text-white md:text-4xl text-2xl font-bold md:mb-10">
              WHY ENTNT?
            </p>
          </div>

          <ul className="list-none space-y-3 hidden sm:block">
            {data.map((item, i) => (
              <li
                key={i}
                className={`block py-0.5 md:text-lg font-medium leading-6 ${
                  i === activeSection ? "text-black" : "text-white"
                } `}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex md:flex-col md:mr-32 md:ml-32 md:space-y-5 md:space-x-0 space-x-2 md:w-1/2  md:overflow-y-auto no-scrollbar md:mt-28 ml-7 pb-8 overflow-x-auto">
          {divisions.map((item, i) => (
            <div
              key={i}
              id={`item-${i}`}
              className="content-section flex flex-col space-y-6 justify-left bg-[#03002D] md:px-20 md:pt-12 md:pb-20 px-7 py-8 rounded-lg md:w-fit min-w-[300px] md:h-full"
            >
              <img className="h-10 w-10" src={item.logo} />
              <p className="text-white font-semibold md:text-xl pr-10">
                {item.title}
              </p>
              <p className="text-white font-normal md:text-lg text-sm text-left">
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* section5 */}
      <div className="">
        <p className="text-black font-bold md:text-4xl text-2xl text-center md:mt-16 mt-10">
          HOW?
        </p>
        <p className="text-black md:text-lg  text-md text-center md:mt-6 mt-4 md:mb-24 mb-16">
          We understand the primary needs of the shipping industry to provide
          practical solutions to actual <br /> challenges in order to enhance
          existing software functionality.
        </p>
      </div>
      <div className="flex flex-wrap space-x-2 justify-center">
        {sets.map((item, i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center md:space-y-6 space-y-3 bg-[#F6F7F8] md:px-12 px-8 pb-10 max-w-[400px] md:mb-20 mb-14 rounded-lg"
          >
            <img className="h-16 w-16 -mt-8" src={item.image} />
            <p className="text-lg font-semibold ">{item.title}</p>
            <p className="text-md text-center">{item.content}</p>
          </div>
        ))}
      </div>

      {/* section6 */}
      <div className="flex flex-col bg-[#03002D] md:px-24 md:py-16 px-7 py-8">
        <div className="flex justify-between">
          <div className="flex md:space-x-4 space-x-2">
            <p className="text-[#847CFF] md:text-4xl text-2xl font-bold">OUR</p>
            <p className="text-white md:text-4xl text-2xl font-bold">PEOPLE</p>
          </div>
          <button
            className="underline text-lg text-[#847CFF]"
            onClick={() => setSelectedItem(null)}
          >
            back
          </button>
        </div>
        <div className="flex md:flex-row flex-col md:space-x-3 md:mt-20 mt-8 md:space-y-0 space-y-4">
          {people.map(
            (item, i) =>
              (selectedItem === item.title || selectedItem === null) && (
                <div
                  key={i}
                  className="flex flex-col items-center justify-center bg-[#100C49] py-8 rounded-lg md:w-1/3 max-h-[400px]"
                  onClick={() => setSelectedItem(item.title)}
                >
                  <img
                    className="h-40 w-40 object-center"
                    src={item.image}
                    alt="image"
                  />
                  <div className="flex justify-start w-full md:mt-20 md:px-10 mt-12 px-7">
                    <div className="space-y-1 mt-3">
                      <p className="text-white font-medium">{item.title}</p>
                      <p className="text-[#685FF2]">{item.name}</p>
                    </div>
                    {/* <div className="space-y-3">
                      <img className="h-8 w-8" src={linkedin} alt="image" />
                      <img className="h-8 w-8" src={mail} alt="image" />
                    </div> */}
                  </div>
                </div>
              )
          )}

          {selectedItem === "CEO" && (
            <div className="md:w-2/3 md:pl-20">
              <p className="text-white text-lg font-medium hidden sm:block">
                CEO
              </p>
              <p className="text-white">
                Omiros, our CEO at ENTNT, is a professional investor with a
                legal background and serves as the key connector within and on
                behalf of our organisation.
                <br />
                <br /> With an extensive network of contacts and his expertise
                both as a business professional and in service-based industries,
                Omiros keenly understands the growing skills shortage faced by
                businesses. Leveraging his links in the shipping industry and
                other invested sectors that include real estate and joint
                ventures with high net-worth individuals, Omiros brings a
                client-centric approach to provide services and products that
                can bring tangible results to clients.
                <br />
                <br /> His insights into the shifting landscape of various
                industries allow him to proactively identify opportunities for
                growth and strategic partnerships through referrals. Omiros'
                multifaceted role ensures that ENTNT stays at the forefront of
                industry developments in a manner that can deliver value to our
                clients.
              </p>
            </div>
          )}
          {selectedItem === "COO" && (
            <div className="md:w-2/3 md:pl-20  ">
              <p className="text-white text-lg font-medium hidden sm:block">
                COO
              </p>
              <p className="text-white">
                Anthony, our COO is a seasoned lawyer with a decade of
                experience at prestigious law firms like Allen & Overy and
                Clifford Chance and experience as an in-house lawyer. He brings
                extensive legal knowledge to our team.
                <br />
                <br />
                Having witnessed the effects of offshoring and AI on the legal
                industry, particularly as a result of his work across multiple
                countries, Anthony understands the legal challenges that arise
                in cross-border transactions and the importance of risk
                mitigation to provide comfort to the counterparties in such
                situations.
                <br />
                <br /> Anthony's investment in ENTNT is founded on his strong
                belief in the potential of a distributed workforce model,
                particularly for companies seeking the ideal balance of talent
                and cost-effectiveness. He has observed how even traditionally
                conservative industries like law have enthusiastically embraced
                this approach. Leveraging his legal expertise, Anthony ensures
                that ENTNT not only operates in complete compliance with
                regulatory requirements but also proactively anticipates and
                addresses legal issues, offering our clients the reassurance
                they need for their investments.
              </p>
            </div>
          )}
          {selectedItem === "Partner-Software" && (
            <div className="md:w-2/3 md:pl-20 ">
              <p className="text-white text-lg font-medium hidden sm:block">
                Partner-Software
              </p>
              <p className="text-white overflow-y-auto no-scrollbar md:h-[400px]">
                At only 27 years old, Marios has already achieved impressive
                milestones in his career, demonstrating his exceptional talent
                and drive for success.
                <br />
                <br /> Marios's journey began with a significant accomplishment
                at the age of 25 when he assumed the role of Technical Lead at a
                well-established Shipmanagement Software firm. Subsequently, he
                joined Microsoft, where he worked on a product developed by
                Softomotive, the first Greek company to be acquired and
                integrated into Microsoft.
                <br />
                <br /> <p className="underline"> Communication</p>
                Marios Sarrigiannis not only excels as an accomplished
                programmer but also possesses exceptional communication skills—a
                rare combination in the industry. His ability to effectively
                communicate becomes particularly valuable when formulating
                projects involving multiple stakeholders, such as research
                initiatives and complex integrations.
                <br />
                <br />
                Marios has a proven track record of working on diverse projects
                involving multiple stakeholders, showcasing his exceptional
                communication skills. Some notable examples include:
                <br />
                <br />
                <ul className="list-disc">
                  <li>
                    {" "}
                    Collaborating on projects for the prestigious Norwegian
                    Sovereign Wealth Fund
                  </li>
                  <li>
                    {" "}
                    Contributing to initiatives funded by the Ontario Research
                    Fund
                  </li>
                  <li>
                    Working with reputable shipping companies that include,
                    among many others, Gaslog, TMS Group and Hartmann Group
                    (Intership)
                  </li>
                  <li>
                    {" "}
                    Engaging with US-listed companies on behalf on Microsoft,
                    such as AT&T
                  </li>
                </ul>
                <br />
                <p className="underline">Remote Teams</p>
                He has extensive experience working with remote teams, including
                his role as a technical lead at a Shipmanagement software
                company, where he successfully managed a team of 10 developers
                dispersed across different locations. Additionally, Marios has
                acted as a communication liaison for remote teams during his
                tenure at Microsoft, facilitating effective collaboration
                between on-site and off-site team members.
                <br />
                <br /> <p className="underline">Artificial Intelligence</p>{" "}
                Marios has gained valuable experience with AI through his work
                at Microsoft. Leveraging Microsoft's special relationship with
                OpenAI, Marios had the rare opportunity to gain early-access
                exposure on LLMs (e.g. GPT-4) granting him unique insight in a
                manner not accessible to professionals outside of Microsoft.
                During his tenure, Marios built an AI copilot for the product he
                was working on, demonstrating his proficiency in developing and
                integrating AI technologies. This experience allowed him to
                delve into the intricacies of this emerging field and gain a
                deep understanding of the potential and inner workings of AI
                technology.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
