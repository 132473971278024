import React from "react";
import Image from "../images/SoftwareImage.png";
import Vector1 from "../images/Vector1.svg";
import Vector2 from "../images/Vector2.svg";
import background from "../images/background.png";
import { CheckIcon } from "@heroicons/react/24/outline";
import Group1 from "../images/Group1.svg";
import Group2 from "../images/Group2.svg";
import Group3 from "../images/Group3.svg";
import Group4 from "../images/Group4.png";
import Group5 from "../images/Group5.png";
import Group6 from "../images/Group6.png";
import Line from "../images/Line.svg";
import { useNavigate } from "react-router-dom";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

export const Software = () => {
  const navigate = useNavigate();
  return (
    <div className="px-2.5 md:px-36 md:mt-24 mt-20 space-y-3 md:pb-20 pb-8">
      {/* section1 */}
      <div className="md:flex  md:flex-row flex flex-col-reverse rounded-tl-3xl bg-[#DAD7FF]">
        <div className="flex flex-col md:flex-col md:justify-left md:pl-28 md:pr-16 md:pb-16 md:pt-12 md:w-1/2 px-7 py-7">
          <div className="flex md:space-x-4 space-x-2 ">
            {" "}
            <p className="text-[#3B31DE] md:text-5xl text-2xl font-bold ">
              Software
            </p>
            <p className="text-black md:text-5xl text-2xl font-bold ">
              Services
            </p>
          </div>
          <p className="text-black md:text-3xl text-xl font-bold md:mt-3 mt-1">
            "Unlock Success ! Seamless Software Solutions, Proven Expertise,
            Instant Delivery!"
          </p>
          <p className="text-[#7064FF] font-bold md:text-xl text-lg md:mt-10 mt-4">
            Why ENTNT?
          </p>
          <p className="text-black text-left font-medium md:text-lg text-md  md:mt-2">
            Thanks to our Established Networks, a Reliable stream of quality,
            sophisticated Management techniques, and high-end Technologies, we
            can fulfill all your software development needs instantly. Our main
            pillars of expertise are at your disposal.
          </p>
          <div className="flex space-x-5 md:mt-12 mt-8 md:mb-0 mb-12 ">
            {/*<button
              type="button"
              className="inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-2 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={() => navigate("/")}
            >
              Get Started
            </button>
            <button
              type="button"
              className=" inline-flex w-full justify-center rounded-lg  text-indigo-600 border-2 border-indigo-600   hover:bg-indigo-600 px-2 py-3 text-md  hover:text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            >
              Book a Meeting
            </button>*/}
          </div>
        </div>
        <div className=" md:mr-20 md:-mt-12 md:w-fit md:mb-20 px-7 -mt-10">
          <img
            src={Image}
            alt="image"
            className="rounded-2xl h-48 w-full md:rounded md:h-full "
          />
        </div>
      </div>

      {/* section2 */}
      <div className="bg-[#03002D] md:bg-[#05005D] md:px-28 md:py-20 px-7 py-10">
        <div>
          <p className="text-[#847CFF] md:text-[#3B31DE] md:text-4xl text-2xl font-bold uppercase">
            Established
          </p>
          <p className="text-white md:text-4xl text-2xl font-bold uppercase ">
            Networks
          </p>
        </div>
        <div className="flex md:flex-row flex-col md:space-x-3 md:mt-12 mt-8 space-y-5 md:space-y-0">
          <div className="flex bg-[#05005D] md:bg-[#03002D] bg-opacity-25 md:bg-opacity-50 flex-col md:pl-10 p-7 md:pr-20 md:py-10 text-white md:w-2/5 justify-start border-2 border-[#847CFF] rounded-lg ">
            <img src={Vector1} className="h-12 w-12" alt="image" />
            <p className="font-medium md:text-2xl text-lg md:mt-6 mt-3">
              Holistic Remote Hiring Approach
            </p>
            <br />
            <p className=" font-normal">
              Hiring remotely goes beyond posting a global job on LinkedIn.
              <br />
              <br />
              Steps taken mirror the process of opening a physical office in
              each location with legally adapted employment contracts taking
              into account regulatory considerations.
              <br />
              <br />
            </p>
            <p className="text-[#847CFF] font-medium text-lg">
              Insider Presence
            </p>
            <br />
            <p>
              {" "}
              One team member has lived or spent multiple months a year in each
              operational location.
            </p>
            <br />
            <p>
              {" "}
              <b> Current network focus</b>
              <br /> India and the Philippines.
              <b>
                <br />
                <br /> Expansion plans
              </b>
              <br /> Developing a network in Turkey and Egypt.
            </p>
          </div>
          <div className="flex flex-col  bg-[#05005D] md:bg-[#03002D] md:bg-opacity-50 bg-opacity-25 md:px-10 md:py-10 p-7 text-white justify-start border-2 border-[#847CFF] rounded-lg md:w-3/5">
            <img src={Vector2} className="h-12 w-12" alt="image" />
            <p className="font-medium md:text-2xl text-lg md:mt-6 mt-3">
              Defining Network
            </p>
            <br />

            <p className="text-[#847CFF] font-medium text-lg">
              Trust can only be built over time
            </p>
            <br />
            <p>
              Extensive experience with offshore teams throughout our careers.
              <br />
              <br /> Established relationships spanning many years.
            </p>
            <br />
            <p className="text-[#847CFF] font-medium text-lg">
              Leveraging Personal Connections
            </p>
            <br />
            <p>
              Acquaintances play a vital role, offering reliable references for
              hiring and ensuring compliance with local laws and job market
              practices.
              <br />
              <br />
              Leveraging the services of the Greek embassy can provide companies
              with valuable and often complimentary high-level assistance.
            </p>
            <br />
            <p className="text-[#847CFF] font-medium text-lg">
              Local Job Market Contacts
            </p>
            <br />
            <p>
              Building relationships with local job markets, including
              universities, recruiting agencies, and service companies available
              for outsourcing.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="relative"> */}

      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="  md:px-28 md:py-20 px-7 py-10 w-full"
      >
        <div>
          <p className="text-[#05005D] md:text-4xl text-2xl font-bold uppercase">
            Reliable stream
          </p>
          <p className="text-white md:text-4xl text-2xl font-bold uppercase ">
            of quality
          </p>
        </div>
        <p className="md:mt-16 mt-7 text-[#05005D] font-bold md:text-xl text-lg">
          Strength in numbers
        </p>
        <div className="flex md:flex-row flex-col md:space-x-10 md:text-lg text-md">
          <div className="flex space-x-1 md:mt-6 mt-4 md:w-1/3">
            <CheckIcon className="h-20 w-20 -mt-6" />
            <div className="text-white flex flex-col">
              <p className="font-medium">
                Numbers Game in Globalized Skills Market
              </p>
              <p>
                With thousands of applications and tests, our top 1% is a
                significantly larger group due to the extensive pool of
                applicants.
              </p>
            </div>
          </div>
          <div className="flex space-x-1 mt-6 md:w-1/3">
            <CheckIcon className="h-28 w-28 -mt-10" />
            <div className="text-white flex flex-col">
              <p className="font-medium">Rigorous Testing </p>
              <p>
                An initial tailored online assessment followed by a single-day
                take-home assignment, with a skills-based interview ensures a
                thorough assessment of candidates' skills.
              </p>
            </div>
          </div>
          <div className="flex space-x-1 mt-6 md:w-1/3">
            <CheckIcon className="h-28 w-28 -mt-10" />
            <div className="text-white flex flex-col">
              <p className="font-medium"> Automated Hiring Process</p>
              <p>
                Our software automates emailing, testing and interview
                scheduling, enabling us to handle a larger pool of candidates
                and reduce the lead-in time in the recruitment process.
              </p>
            </div>
          </div>
        </div>
        <img className="md:mt-12 w-full h-1 mt-4" src={Line} alt="image" />
        <p className="md:mt-16 mt-4 text-[#05005D] font-bold md:text-xl text-lg">
          Scalability
          <p className="text-white">Strategic Spare Staff Management</p>
        </p>
        <div className="flex md:flex-row flex-col md:space-x-10 md:text-lg text-md">
          <div className="flex space-x-1 md:mt-6 mt-4 md:w-1/3">
            <CheckIcon className="h-24 w-24 -mt-8" />
            <div className="text-white flex flex-col">
              <p className="font-medium">
                Maintaining surplus staff allows us to
              </p>
              <p>
                Afford the luxury of having more personnel than immediate needs{" "}
                <br />
                <br />
                Initiate projects promptly by having a readily available
                workforce. <br />
                <br />
                Strategically manage turnover without dependency on individual
                high-performers, preventing disruptions in case of employee
                departure.
              </p>
            </div>
          </div>
          <div className="flex space-x-1 mt-6 md:w-1/3">
            <CheckIcon className="h-28 w-28 -mt-10" />
            <div className="text-white flex flex-col">
              <p className="font-medium">
                {" "}
                Continuous Optimization Through Job Testing{" "}
              </p>
              <p>
                We consistently run tests with job posts even when not actively
                hiring, optimizing our processes and remaining prepared to
                onboard individuals beyond our current scope.
              </p>
            </div>
          </div>
          <div className="flex space-x-1 mt-6 md:w-1/3">
            <CheckIcon className="h-20 w-20 -mt-6" />
            <div className="text-white flex flex-col">
              <p className="font-medium"> Lead Time to New hire</p>
              <p>
                Our lead time to hire is flexible, ranging from 7 to 20 days,
                based on the urgency and specific requirements of the job post.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="text-black font-bold md:text-4xl text-2xl text-center md:mt-20 mt-10 md:uppercase">
          Management
        </p>

        <div className="flex flex-wrap space-x-2 justify-center md:mt-20 mt-16">
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[400px] mb-20 rounded-lg">
            <img className="h-16 w-16 -mt-8" src={Group1} />
            <p className="md:text-lg text-md font-bold text-center">
              Complete Delegation:
              <br /> Regulatory Compliance and <br />
              HR Management
            </p>
            <p className="text-md text-center">
              We handle the complete spectrum of compliance and HR tasks,
              including contracts, payments, raises, appraisals, and upskilling
              initiatives. <br />
              <br />
              Complete delegation of administrative tasks, absorbed within our
              existing scope of work and pricing.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[400px] mb-20 rounded-lg">
            <img className="h-16 w-16 md:-mt-12 -mt-8" src={Group2} />
            <p className="md:text-lg text-md font-bold text-center">
              Proactive <br />
              Team Management <br />
              with Cultural Sensitivity{" "}
            </p>
            <p className="text-md text-center">
              Our teams benefit from proactive oversight from Greek project
              managers with experience in handling offshore remote teams,
              addressing cultural nuances, and providing clients with
              customizable levels of involvement in the management process.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-8 pb-10 max-w-[400px] md:mb-20 mb-6 rounded-lg">
            <img className="h-16 w-16 md-mt-12 -mt-8" src={Group3} />
            <p className="md:text-lg text-md font-bold text-center">
              {" "}
              Strategic
              <br /> Upskilling for
              <br /> Talent Development
            </p>
            <p className="text-md text-center">
              Our emphasis on upskilling ensures a pool of skilled and tested
              employees who have the opportunity to progress through the ranks.
              This strategic approach not only enhances retention but also
              offers cost advantages, allowing us to provide high-quality talent
              at competitive prices.
            </p>
          </div>
        </div>
      </div>

      <div className="md:pl-28 md:py-20 md:pr-60 bg-[#03002D] text-white px-7 py-8">
        <p className="md:text-4xl text-2xl font-bold uppercase ">
          Technologies
        </p>
        <p className=" md:mt-10 mt-4 md:pr-24">
          Shipping is the embodiment of globalization before the term was
          invented: conducted in the English-language, rich with a multicultural
          diversity of people, ships, markets and deeply international, shipping
          is the lifeblood of global trade.
        </p>
        <div className="flex md:flex-row flex-col justify-between text-md font-bold md:mt-20 mt-8 md:space-x-3 space-y-5 md:space-y-0">
          <div className="flex flex-col md:space-y-3 space-y-2">
            <p>
              Web <br />
              development
            </p>
            <img src={Group4} />
          </div>
          <div className="flex flex-col md:space-y-3 space-y-2">
            <p>
              Desktop <br />
              development
            </p>
            <img src={Group5} />
          </div>
          <div className="flex flex-col md:space-y-3 space-y-2">
            <p>
              Cross-platform mobile
              <br /> app development
            </p>
            <img src={Group6} className="md:h-fit h-28" />
          </div>
        </div>
        <div className="bg-[#01000D] mt-5 md:py-2 py-4 px-4">
          <p className="text-center">
            Initial hires specialize in these technologies, ensuring quality and
            relevance in meeting market demands.
          </p>
        </div>
        <img className="  md:mt-12 w-full h-1 mt-8" src={Line} alt="image" />
        <p className="font-bold md:mt-10 text-lg mt-6">
          Unlocking Rare Skills Globally
        </p>
        <div className="flex md:flex-row flex-col md:justify-around md:mt-8 mt-4 md:space-y-0 space-y-3">
          {" "}
          <div className="flex space-x-1 md:w-1/3">
            <CheckIcon className="h-24 w-24 -mt-8 text-[#3B31DE]" />

            <p>
              For specialized or rare skills, our advice to clients is
              straightforward: a larger talent pool yields better chances of
              finding that unique skill set.
            </p>
          </div>
          <div className="flex space-x-1 md:w-1/3">
            <CheckIcon className="h-24 w-24 -mt-8 text-[#3B31DE]" />

            <p>
              By leveraging a worldwide talent pool, we enhance the probability
              of discovering rare, high-demand, or dated skills that might be
              elusive within a narrower search.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
