import React, { useState } from "react";
import image from "../images/bg1.png";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import Group from "../images/Group.png";
import arrow from "../images/arrow.svg";
import Shipping from "../images/Shipping.svg";
import Property from "../images/Property.svg";
import Software from "../images/Software.svg";
import Ellipse from "../images/Ellipse 4.svg";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

export const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    {
      title1: "Digital",
      title2: "Expansion",

      content:
        "Optimise your workforce with our comprehensive solutions that includes remote services, products and a global partner network.\n\nFrom end-to-end talent and project management to our simplified billing, explore how our offerings can lead to high-performance teams and exceptional results",
    },
    {
      title1: "BEYOND",
      title2: "BORDERS",
      subtitle: "Navigating Remote Teams",
      content:
        "From finding reliable providers to ensuring compliance: a high-level introductory guide on how to source offshore service providers, integrate and manage remote teams and address compliance concerns for successful remote work",
    },
    {
      title1: "REMOTE",
      title2: "STACK",
      subtitle: "Discover how our AI co-pilots enhance workflows",
      content:
        "Our recruitment system identifies top candidates and our secure workspace and remote operations ensure compliance and data protection",
    },
    {
      title1: "OPERATIONAL",
      title2: "SUPPORT",
      subtitle:
        "Simplified operations with skilled management and strategic hiring",
      content:
        "Experience hassle-free day-to-day oversight, dedicated project managers and a unique approach to recruitment that provides high-performing hires",
    },
  ];
  const showItem = (index: number) => {
    setCurrentIndex(index);
  };

  const nextItem = () => {
    const nextIndex = (currentIndex + 1) % items.length;
    showItem(nextIndex);
  };
  return (
    <div className="px-2.5 md:px-36 md:pb-20 pb-8">
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className=" md:px-20 md:py-20 md:mt-32 md:rounded-tr-3xl rounded-tl-3xl mt-16 h-[550px] md:h-full"
      >
        <div className="absolute top-24 md:0 md:static px-7 md:-mt-40 ml-7 mr-10 bg-[#05004B] bg-opacity-90 rounded-xl max-w-[600px] sm:w-auto md:p-20 md:pt-12 p-6">
          <p className="text-white md:text-5xl text-3xl font-bold">
            Remote Teams
          </p>
          <p className="text-indigo-600 md:text-5xl text-3xl font-bold">
            Made Easy
          </p>
          <p className="mt-8 text-sm md:text-[18px] text-white">
            ENTNT is a complete workforce solutions provider, offering an
            integrated remote service that combines recruitment, training and
            management of personnel alongside customized software and AI to
            allow businesses to optimize or grow their operations.
            <br />
            <br /> With a personalized, research-intensive focus on the global
            labor market,
            <br />
          </p>
          <p className="text-indigo-200 font-medium md:text-xl text-md">
            "we offer skilled professionals and technological aids at the scale
            and level of commitment that you require".
          </p>
          <br />
          <p className="md:text-[18px] text-sm text-white">
            We believe in maintaining a human-centric approach to staffing which
            fosters strong connections.
          </p>
        </div>
      </div>
      {/* section 2 */}
      <div className="flex md:flex-row flex-col mt-3 md:space-x-3 md:space-y-0 space-y-3">
        <div className="bg-[#DAD7FF] md:px-28 md:py-16 px-7 py-8 md:w-1/2">
          <div className="flex md:space-x-3 space-x-2 md:mb-8 mb-4">
            <p className="text-indigo-600 md:text-4xl text-3xl font-bold uppercase">
              our
            </p>
            <p className="text-black md:text-4xl text-3xl font-bold uppercase">
              Services
            </p>
          </div>
          <p className="text-md">
            We are a complete workforce solutions provider, offering an
            integrated remote service that combines recruitment, training and
            management of personnel alongside customized software and AI to
            allow businesses to optimize or grow their operations. <br /> <br />
            Combined with our own recruitment and corporate memory tools, we use
            the latest research and data management to help businesses with
            their resourcing, scaling and project requirements.
          </p>
          <img
            className="bg-[#CAC6FF] rounded-lg p-6 mt-8"
            src={Group}
            alt="Your Company"
          />
        </div>

        <div
          id="controls-carousel"
          className="flex space-x-3 relative md:w-1/2 overflow-x-auto"
          data-carousel="static"
        >
          <div className="relative md:overflow-hidden flex md:space-x-0 space-x-3">
            {items.map((item, index) => (
              <div
                key={index}
                className={`${
                  index === currentIndex ? "" : "md:hidden"
                }  bg-[#C2BDFF] md:px-28 md:py-16 px-7 py-8 md:h-full w-[300px] md:w-[800px]`}
                data-carousel-item
              >
                <div className="flex flex-col mb-8">
                  <p className="text-black md:text-4xl text-3xl font-bold uppercase">
                    {item.title1}
                  </p>

                  <p className="text-indigo-600 md:text-4xl text-3xl font-bold uppercase">
                    {item.title2}
                  </p>
                </div>
                <p className="text-[#3B31DE] text-lg font-medium my-4">
                  {item.subtitle}
                </p>
                <p className="text-lg font-medium leading-tight">
                  {item.content.split("\n\n").map((paragraph, i, arr) => (
                    <React.Fragment key={i}>
                      {i > 0 && <br />}
                      {paragraph}
                      {i < arr.length - 1 && <br />}{" "}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            ))}
          </div>
          <button
            type="button"
            className="bg-[#C2BDFF] items-center justify-center max-h-full p-10 cursor-pointer group focus:outline-none hidden sm:block "
            data-carousel-next
            onClick={nextItem}
          >
            <img className="h-8  w-8" src={arrow} alt="Your Company" />
          </button>
        </div>
      </div>

      {/* section 3  */}
      <div className="relative bg-[#03002D] mt-3 md:h-[620px] md:px-28 md:py-20 px-7 py-8">
        <p className="text-indigo-600 md:text-4xl text-2xl font-bold uppercase">
          Areas
        </p>
        <p className="text-white md:text-4xl text-2xl font-bold uppercase">
          Of Operation
        </p>

        <div className="md:absolute md:top-52 md:left-28 md:right-28 md:mt-0 mt-6 flex md:flex-row md:justify-between flex-col md:space-x-6 md:space-y-0 space-y-3">
          <div className="flex md:flex-row flex-col-reverse md:justify-between bg-[#D5D3FF] rounded-2xl bg-opacity-90">
            <div className="flex flex-col md:w-auto md:px-12 md:pt-16 p-7 pr-16">
              <p className="text-black text-2xl text-md font-bold ">Software</p>
              <p className="text-black text-2xl text-md font-normal">
                Services
              </p>
              <p className="text-md text-left mt-4 w-full">
              Comprehensive software solutions from development to deployment. Services include design, coding, testing, and maintenance.
              </p>
              {/* <a href="mailto:contact@en-tnt.com">
                <button
                  type="button"
                  className="md:mt-40 mt-8  w-3/4 inline-flex md:w-full justify-center rounded-lg bg-indigo-600 px-4 py-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  Contact Us
                </button>
              </a> */}
            </div>
            <img
              className="md:w-1/2 md:h-full h-40 object-cover  rounded-xl "
              src={img1}
              alt="Your Company"
            />
          </div>
          <div className="flex md:flex-row flex-col-reverse md:justify-between bg-[#D5D3FF] rounded-2xl bg-opacity-90">
            <div className="flex flex-col md:w-auto md:px-10 md:pt-16 p-7 pr-16">
              <p className="text-black text-2xl text-md font-bold">
                General Office
              </p>
              <p className="text-black text-2xl text-md font-normal">
                Services
              </p>
              <p className="text-md text-left mt-4 w-full">
              Essential administrative services that support business operations. Tasks include data management, routine office work, and customer support, integral to any company's operations.
              </p>
              {/* <a href="mailto:contact@en-tnt.com">
                <button
                  type="button"
                  className="md:mt-40 mt-8  w-3/4  inline-flex md:w-full justify-center rounded-lg bg-indigo-600 px-4 py-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 sm:col-start-2"
                >
                  Contact Us
                </button>
              </a> */}
            </div>
            <img
              className="md:w-1/2 md:h-full h-40 object-cover rounded-xl"
              src={img2}
              alt="Your Company"
            />
          </div>
        </div>
      </div>

      {/* section 4 */}
      <div className="md:mt-32 mt-16 md:mb-10 mb-8">
        <p className="text-black md:text-4xl text-2xl text-md font-bold uppercase text-center">
          Areas of expertise
        </p>
        <p className="text-md text-center font-normal mt-4 w-full px-5">
          We're a dynamic group of individuals who are passionate about what we
          do and <br /> dedicated to delivering the best results for our
          clients.
        </p>
      </div>

      <div className="flex  md:flex-row flex-col md:justify-center md:space-x-3 w-full">
        <div className=" bg-[#F6F7F8] md:px-24 md:my-10 md:py-12 px-7 py-8 flex md:flex-col md:items-center rounded-lg md:space-x-0 space-x-12 z-10">
          <img className="" src={Shipping} alt="Your Company" />
          <div className="flex flex-col align-left">
            <div className="md:mt-12 flex md:flex-col items-center md:space-x-0 space-x-1">
              <p className="text-black md:text-2xl text-xl font-bold ">
                Shipping
              </p>
              <p className="text-black md:text-2xl text-xl font-normal">
                Technology
              </p>
            </div>
            {/* <button
              type="button"
              className="md:mt-20 mt-3 text-lg text-indigo-600 font-medium text-left md:text-center"
            >
              Contact Us
            </button> */}
          </div>
        </div>
        <div className=" bg-[#F6F7F8] md:px-24 mt-3 md:my-10 md:py-12 px-7 py-10 flex md:flex-col md:items-center rounded-lg md:space-x-0 space-x-8 z-10">
          <img className="h-16" src={Property} alt="Your Company" />
          <div className="flex flex-col align-left">
            <div className="md:mt-12 flex md:flex-col items-center md:space-x-0 space-x-1">
              <p className="text-black md:text-2xl text-xl font-bold ">
                Property
              </p>
              <p className="text-black md:text-2xl text-xl font-normal">
                Management
              </p>
            </div>
            {/* <button
              type="button"
              className="md:mt-20 mt-3 text-lg text-indigo-600 font-medium text-left md:text-center"
            >
              Contact Us
            </button> */}
          </div>
        </div>
        <div className=" bg-[#F6F7F8] md:px-24 mt-3 md:my-10 md:py-12 px-7 py-10 flex md:flex-col md:items-center rounded-lg md:space-x-0 space-x-4 z-10">
          <img className="" src={Software} alt="Your Company" />
          <div className="flex flex-col align-left">
            <div className="md:mt-12 flex md:flex-col items-center md:space-x-0 space-x-1">
              <p className="text-black md:text-2xl text-xl font-bold ">
                Software
              </p>
              <p className="text-black md:text-2xl text-xl font-normal">
                Development
              </p>
            </div>
            {/* <button
              type="button"
              className="md:mt-20 mt-3 text-lg text-indigo-600 font-medium text-left md:text-center"
            >
              Contact Us
            </button> */}
          </div>
        </div>
      </div>
      <div className="flex md:justify-center overflow-x-clip ">
        <img
          src={Ellipse}
          alt="image"
          className=" md:-mt-80 z-0 h-80 md:mr-4 ml-64 md:ml-0 -mt-96"
        />
      </div>
      {/* section 5 */}
      <div className="flex md:flex-row flex-col bg-[#03002D] md:mt-20 mt-10">
        <div className="flex flex-col justify-left space-y-8 md:pl-24 md:pr-4 md:py-16 md:w-2/5 px-7 py-8">
          <div className="flex md:flex-col md:space-x-0 space-x-2">
            {" "}
            <p className="text-indigo-600 md:text-4xl text-2xl font-bold uppercase">
              Work
            </p>
            <p className="text-white md:text-4xl text-2xl font-bold uppercase">
              with us
            </p>
          </div>
          <p className="text-[#B3AEFF] md:text-lg text-md font-medium">
            Join us as we transform <br />
            remote work!
          </p>
          <p className="text-white text-left">
            With an ongoing mandate to a large New York property management,
            ENTNT is actively engaged in both broadening its service portfolio
            and expanding its clientele across diverse industries.
          </p>
          <div className="flex md:flex-row flex-col md:space-x-5">
            <a className="text-white flex" href="mailto:hr@entnt.in">
              Apply now
              <ArrowLongRightIcon
                className="h-6 w-6 ml-2 text-white"
                aria-hidden="true"
              />
            </a>
            {/* <span className="text-white flex">
              Meet our leadership
              <ArrowLongRightIcon
                className="h-6 w-6 ml-2 text-white"
                aria-hidden="true"
              />
            </span> */}
          </div>
        </div>
        <div className="border-2 border-[#847CFF] rounded-xl md:mx-24 md:my-16 md:p-16 px-7 mx-7 py-7 mb-7 bg-[#05005D] bg-opacity-25 md:w-3/5">
          <p className="text-white text-left">
            Our founding team comprises of cosmopolitan individuals with a
            background in shipping, software, business and law and credentials
            from top-tier employers, including former Microsoft engineers. ENTNT
            is well capitalized from its founders, allowing it to operate as an
            independent company with the ability to steadfastly uphold its
            strategy and values, unencumbered by compromises from external
            funding partners. <br />
            <br /> As a fully remote company and with a diverse ethos, we select
            top talents worldwide based on competency. We prioritize individuals
            who exemplify respect for colleagues, collaborate toward shared
            objectives and commit to deliver excellence in their work
          </p>
        </div>
      </div>
    </div>
  );
};
